import { createIcon } from '@chakra-ui/react'

export const AccountsIcon = createIcon({
  displayName: 'AccountsIcon',
  path: (
    <g fill='currentColor' fillRule='nonzero'>
      <g>
        <path
          d='M16.051 0.116127C18.1998 -0.456855 20.3077 1.16268 20.3077 3.38646V4.1825H20.6154C22.4847 4.1825 24 5.69785 24 7.56712V18.644C24 20.5133 22.4847 22.0287 20.6154 22.0287H3.38462C1.51535 22.0287 0 20.5134 0 18.644V6.99673C0 5.46333 1.0309 4.1215 2.51252 3.72639L16.051 0.116127ZM7.96713 4.1825L16.5267 1.89995C17.5034 1.6395 18.4615 2.37564 18.4615 3.38646V4.1825H7.96713ZM1.84615 7.56712V18.644C1.84615 19.4937 2.53494 20.1825 3.38462 20.1825H20.6154C21.4651 20.1825 22.1538 19.4937 22.1538 18.644V7.56712C22.1538 6.71744 21.4651 6.02865 20.6154 6.02865H3.38462C2.53495 6.02865 1.84615 6.71745 1.84615 7.56712ZM17.5385 13.4133C17.7084 13.4133 17.8462 13.2756 17.8462 13.1056C17.8462 12.9356 17.7084 12.7979 17.5385 12.7979C17.3685 12.7979 17.2308 12.9356 17.2308 13.1056C17.2308 13.2756 17.3685 13.4133 17.5385 13.4133ZM16 13.1056C16 12.256 16.6888 11.5671 17.5385 11.5671C18.3881 11.5671 19.0769 12.256 19.0769 13.1056C19.0769 13.9552 18.3881 14.644 17.5385 14.644C16.6888 14.644 16 13.9552 16 13.1056Z'
          id='Shape'
        ></path>
      </g>
    </g>
  ),
  viewBox: '0 0 24 23',
})
