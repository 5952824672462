import { createIcon } from '@chakra-ui/react'

export const EditPen = createIcon({
  displayName: 'EditCircle',
  path: (
    <path
      d='M1.84917 10.1093L11.3532 0.603713C12.1345 -0.177475 13.4032 -0.177475 14.1845 0.603713L15.4157 1.8359C15.5126 1.9334 15.597 2.03871 15.672 2.14965C16.1845 2.9259 16.1001 3.98121 15.4157 4.66559L5.90948 14.1687C5.86885 14.2093 5.82823 14.2218 5.78448 14.2875C5.48448 14.5531 5.12823 14.7218 4.74073 14.8625L0.980415 15.9687C0.71729 16.0468 0.432603 15.975 0.23854 15.7531C0.0444715 15.5875 -0.0280847 15.3031 0.0493528 15.0375L1.15542 11.2781C1.28542 10.8375 1.52385 10.4343 1.84917 10.1093ZM2.59448 11.7L1.87635 14.1437L4.31885 13.425C4.49073 13.3656 4.7001 13.2562 4.8501 13.1093L11.9876 5.96871L10.022 4.00309L2.90979 11.1687C2.89135 11.1875 2.87354 11.2062 2.85635 11.2281C2.73573 11.3625 2.64604 11.525 2.59448 11.7Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 16 16',
})
