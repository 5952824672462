import { createIcon } from '@chakra-ui/react'

export const NarwhalIcon = createIcon({
  displayName: 'NarwhalIcon',
  path: (
    <g fill='currentColor'>
      <path d='M639.3 20.7c2.4-7.8-1.4-16.1-8.9-19.4s-16.2-.4-20.2 6.6l-93.5 160.4c-16.9-5.4-34.9-8.3-53.6-8.3-50.9 0-100.7 17.5-140.2 49.6L154.1 346.8c-10.5 8.5-26.1 1.1-26.1-12.4v-81.3l29.9-20.9c21.4-15 34.1-39.4 34.1-65.5V112c0-6-3.3-11.4-8.6-14.2s-11.7-2.3-16.6 1.1L96 148.5 25.2 98.9c-4.9-3.4-11.3-3.8-16.6-1.1S0 106 0 112v54.7c0 26.1 12.7 50.6 34.1 65.5L64 253.1V352c0 88.4 71.6 160 160 160h240c97.2 0 176-78.8 176-176 0-51.4-22.3-97.7-57.8-129.9l57-185.4zM496 280a24 24 0 1 1 0 48 24 24 0 1 1 0-48z' />
    </g>
  ),
  viewBox: '0 0 640 512',
})
